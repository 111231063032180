export const contact = '+91 9617645975, +91 7509524290';
export const email = 'saikripaengineering@gmail.com';
export const address ='Near Nai Duniya Press, Rau-453331, Indore (M.P)';
export const social = {
    instagramLink: "https://www.instagram.com/sai.kripa.engineering/",
    youtubeLink:"https://www.youtube.com/channel/UCZFsGvksWdNlAP5eoYofGdA",
    twitterLink:"https://twitter.com/sai_kripa_en01",
    linkedinLink:"",
    facebookLink:""
}
export const founderContact =["+91 8878350068", "+91 7509524290"]