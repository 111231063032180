import React, { useEffect, useRef } from 'react'
import Base from '../Component/Base'
import VideoHeader from '../Component/VideoHeader';
import videoBg from '../assets/videos/Contact-heading.mp4';
import { toast } from 'react-toastify';
import emailjs from '@emailjs/browser';
import { Col, Container, Input, Row } from 'reactstrap';
import { MdLocationPin } from 'react-icons/md';
import { address, contact, email } from '../Component/AppConstants';
import { FaEnvelope} from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa6';

function ContactUs() {

   useEffect(()=>{
      document.title='Connect With Us - Sai Kripa Engineering';
      document.description='Get a Quote or Ask a Question - Contact Sai Kripa ENgineering today via phone, email, or our contact form.';
    },[]);

    // Sending form data to email
   const form = useRef();

   // checking empty fields
   const checkFormEmpty = () => {
      const formFields = form.current.querySelectorAll('input[type="text"], input[type="email"],textarea'); // Select relevant fields like name, email
    
      // Check each field for emptiness
      const isEmptyField = (field) => {
         // Handle different input types (text, email, etc.)
         switch (field.type) {
            case 'text':
            case 'email':
            return field.value.trim() === '';
            case 'textarea':
            return field.value.trim() === ''; // Check for trimmed whitespace in textarea
            default:
            return false;
         }
       };
       
      for (const field of formFields) {
        if (isEmptyField(field)) {
          return false; // Early return to indicate empty form
        }
      }
   
      return true; // Indicate successful validation
    };
    
   const sendEmail = (e) => {
      e.preventDefault();

      console.log();
      // client side validation
      if (!checkFormEmpty()) {
          toast.error("Please Fill the form correctly...",{
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          return;
      }

      // Calling EmailJs Service to send data
  
      emailjs
        .sendForm('service_sp6kvyc', 'template_5iddjbr', form.current, {
          publicKey: '6M-tRtzedun2l6DjX',
        })
        .then(
          () => {
            toast.success("Thank You for contacting us.",{
               position: "top-center",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,    
               });
          },
          (error) => {
            console.log('FAILED...', error.text);
            toast.error("Something went wrong!",{
               position: "top-center",
               autoClose: 4000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,    
               });
          },
        );

        e.target.reset();
    };

   useEffect(()=>{
      document.title='Connect With Us - Sai Kripa Engineering';
      document.description='Get a Quote or Ask a Question - Contact Sai Kripa ENgineering today via phone, email, or our contact form.';
    },[]);

  const headerData = {
    vid: videoBg,
    title: "Let's Build Something Great Together",
    description: "Contact Sai Kripa Engineering."
  };

  return (
    <Base>
        <VideoHeader videoBg={headerData.vid} title={headerData.title} description={headerData.description} />

        <Container className='contact mt-5'>
            <Row className="section-title text-center">
               <Col data-aos='fade-up' md={12}>
                  <h3>Contact Us</h3>
                  <p className="lead">Use to contact form below to contact us regarding any of our services or for recruitment information. Our hard working admin team will get back to you as soon as possible.</p>
               </Col>
            </Row>
            
            <Row>
               <Col md={4} data-aos='fade-right'>
                  <div className="contant-info">
                     <ul className="item-display-block">
                        <li>
                           <div className="info-shape accent-color background fs-23">
                              <div className="icon"><MdLocationPin className='fa fa-home' /></div>
                           </div>
                           <div className="info-content">
                              <h6 className="uppercase"> Address:</h6>
                              <p> {address} </p>
                           </div>
                        </li>
                        <li>
                           <div className="info-shape accent-color background fs-23">
                              <div className="icon"><FaPhone className="fa fa-volume-control-phone" rotate={360} /></div>
                           </div>
                           <div className="info-content">
                              <h6 className="uppercase"> Phone No:</h6>
                              <p> {contact}</p>
                           </div>
                        </li>
                        <li>
                           <div className="info-shape accent-color background fs-23">
                              <div className="icon"><FaEnvelope class="fa fa-envelope-o" /></div>
                           </div>
                           <div className="info-content">
                              <h6 className="uppercase"> Email Address:</h6>
                              <p>{email}</p>
                           </div>
                        </li>
                     </ul>
                  </div>
               </Col>
               <Col md={8} data-aos='fade-left'>
                  <div className="contact_form">
                     <div id="message"></div>
                     <form id="contactform" ref={form} onSubmit={sendEmail} name="contactform" >
                        <fieldset>
                          <Row>
                            <Col md={6} sm={6} xs={6} lg={6}>
                                <Input required type="text" name="first_name" id="first_name" className="form-control" placeholder="First Name" />
                            </Col>
                            <Col md={6} sm={6} xs={6} lg={6}>
                                <Input required type="text" name="last_name" id="last_name" className="form-control" placeholder="Last Name" />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} sm={12} xs={12} lg={12}>
                                <Input required type="email" name="email" id="email" className="form-control" placeholder="Your Email" />
                            </Col>
                            <Col md={12} sm={12} xs={12} lg={12}>
                                <Input required type="text" name="phone" id="phone" className="form-control" placeholder="Your Website" />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} sm={12} xs={12} lg={12}>
                                <textarea required class="form-control" name="comments" id="comments" rows="6" placeholder="Give us more details.."></textarea>
                            </Col>
                            <Col className='text-center'>
                                <button type="submit" value="Submit" id="submit" className="btn" >Get a Quote </button>
                            </Col>
                          </Row>
                        </fieldset>
                     </form>
                  </div>
               </Col>
            </Row>
         </Container>

         {/* MAP */}
         <div className="map-main" data-aos='zoom-in'>
         <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3682.1988956182213!2d75.80357167530263!3d22.64637177943931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDM4JzQ2LjkiTiA3NcKwNDgnMjIuMSJF!5e0!3m2!1sen!2sin!4v1711967817410!5m2!1sen!2sin" width={'100%'} height={'500px'} />
         </div>
    </Base>
  )
}

export default ContactUs