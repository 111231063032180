import React from 'react'
import './VideoHeader.css'

const VideoHeader = ({videoBg, title, description}) => {
  return (
    <div className='main'>
        <div className="overlay"></div>
        <video src={videoBg} autoPlay loop muted />
        <div className="content">
            <h1 data-aos='fade-down' style={{fontSize:"3rem", fontWeight:"700", fontFamily:"'Work Sans', sans-serif"}} >{title}</h1>
            <span data-aos='fade-up' style={{fontSize:"1.5rem", fontWeight:"500", color:'var(--primary-color)',fontFamily:"'Work Sans', sans-serif"}}>{description}</span>
        </div>
    </div>
  )
}

export default VideoHeader