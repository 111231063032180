
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import MSWorks from './Pages/MSworks';
import SSWorks from './Pages/SSworks';
import ContactUs from './Pages/ContactUs';
import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import WBAvtar from'./assets/Sai kripa engineering.png';

function App() {

  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 800
    });
  }, [])

  return (
    <div className="App">
      <ToastContainer />
      <FloatingWhatsApp phoneNumber='9669469074' avatar={WBAvtar} accountName='SKE' darkMode={true} notification={true} notificationSound={true} chatMessage='Hi there! How can I help you today?' statusMessage='SAI KRIPA ENGINEERING'/>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/ms-works' element={<MSWorks />} />
          <Route path='/ss-works' element={<SSWorks />} />
          <Route path='/contact' element={<ContactUs />} />
        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
