import React, { useEffect } from 'react'
import Base from '../Component/Base'
import videoBg from '../assets/videos/MS-heading.mp4';
import VideoHeader from '../Component/VideoHeader';
import { Col, Container, Row, Table } from 'reactstrap';

import AR1 from '../assets/MS-works/AmmoniaReceiver1.jpg'
import AR2 from '../assets/MS-works/AmmoniaReceiver2.jpg'
import AR3 from '../assets/MS-works/AmmoniaReceiver3.jpg'
import PV1 from '../assets/MS-works/pressure-vessel-1.jpg'
import PV2 from '../assets/MS-works/pressure-vessel-2.jpg'
import PV3 from '../assets/MS-works/pressure-vessel-3.jpg'
import LPR1 from '../assets/MS-works/LPR.jpg';
import LPR2 from '../assets/MS-works/HPR.jpg';
import SBC1 from '../assets/MS-works/screw-belt1.jpg';
import SBC2 from '../assets/MS-works/screw-belt2.jpg';
import SBC3 from '../assets/MS-works/screw-belt3.jpg';
import LPR3 from '../assets/MS-works/fuel-oil-storage-tank-1.jpg'
import SW1 from '../assets/MS-works/sand-washer1.jpg';
import SW2 from '../assets/MS-works/sand-washer2.jpg';
import SW3 from '../assets/MS-works/sand-washer3.jpg';
import CPU1 from '../assets/MS-works/ceramic-fiber-paper-unit1.jpg';
import CPU2 from '../assets/MS-works/ceramic-fiber-paper-unit2.jpg';

const works = [
  //1
  { 
    name: 'Ammonia Receiver', 
    tableContent:[
      {fieldName: 'Capacity', fieldValue: '1000-6000 L, As per company requirement'}, 
      {fieldName:'Material', fieldValue: 'Mild Steel'},
      {fieldName:'Usage/Application', fieldValue: 'Industries and Cold Storage'},
      {fieldName:'Max Design Pressure', fieldValue: '20-40 psi'},
      {fieldName:'Brand', fieldValue: 'SKE'},
      {fieldName:'Orientation', fieldValue: 'Horizontal'},
      {fieldName:'Surface Finishing', fieldValue: 'paint coated'}, 	
    ],
    description: 'We are involved in offering a precisely designed Horizontal Ammonia Receiver which is created and manufactured using quality approved raw material. Our offered range is highly popular in the market for their reliability and durability. Our offered products are sternly checked by our quality analyst on well defined parameters to ensure their faultless delivery at client premises.', 
    features:['Compact Design', 'High Functionality', 'Great Capacity'],
    images: [AR1,AR2,AR3], 
    imgHeight:'400px'
  },
  //2
  { 
    name:'Pressure Vessel & Tank',
    tableContent:[
      {fieldName: 'Capacity', fieldValue: '1000-4000 L, As per company requirement'}, 
      {fieldName:'Material', fieldValue: 'Mild Steel'},
      {fieldName:'Usage/Application', fieldValue: 'Industries and Cold Storage'},
      {fieldName:'Max Design Pressure', fieldValue: '20-40 psi'},
      {fieldName:'Brand', fieldValue: 'SKE'},
      {fieldName:'Orientation', fieldValue: 'Horizontal'},
      {fieldName:'Surface Finishing', fieldValue: 'paint coated'}, 	
    ],
    description: 'Sai Kripa Industries is a recognized organization in the market, highly indulged in Manufacturing and Service Providing an extensive collection of Pressure Vessels, Heat Exchangers and Heavy Fabrication Work.', 
    features:['Compact Design', 'High Functionality', 'Great Capacity'],
    images: [PV1,PV2,PV3], 
    imgHeight:'230px'
  },
  //3
  { 
    name:'Ammonia LPR & HPR and Fuel-Oil Storage tank',
    tableContent:[
      {fieldName: 'Tank Type', fieldValue: 'Ammonia LPR & HPR Fuel-Oil Storage Tank'}, 
      {fieldName:'Capacity', fieldValue: 'Customizable'},
      {fieldName:'Material', fieldValue: 'High-quality Steel'},
      {fieldName:'Coating', fieldValue: 'Corrosion-resistent coating'},
      {fieldName:'Pressure rating', fieldValue: 'Designed for high pressure applications'},
      {fieldName:'Safty Features', fieldValue: 'Double-walled construction, Leak detection System, Overpressure relief valves, Emergency venting system, Compilance with industry safety standards'},
      {fieldName:'Additional Feature', fieldValue: 'Access manways for inspection and maintanence, Integrated lifting lugs for easy transport, Customizable fittings and connections, Optional heating or cooling syatems'},
      {fieldName:'Appilication', fieldValue: 'Industrial fuel-oil-storage, Chemical processing plants, Power generation facilities, Refineries and petrochemical industries'}, 	
    ],
    description: 'Introducing our cutting-edge Ammonia LPR & HPR Fuel-Oil Storage Tanks - meticulously engineered for optimal performance and safety. Built to withstand harsh conditions, our tanks offer unparalleled reliability and durability. Trust in our expertise to safeguard your fuel-oil storage needs with precision engineering and superior quality.', 
    features:['Compact Design', 'High Functionality', 'Great Capacity'],
    images: [LPR1,LPR2,LPR3], 
    imgHeight:'230px'
  },
  
  //4
  { 
    name:'Sand Washer',
    tableContent:[
      {fieldName: 'Model', fieldValue: 'Sand Washer Machine'}, 
      {fieldName:'Dimensions', fieldValue: 'Compact design for space efficiency'},
      {fieldName:'Capacity', fieldValue: 'Efficient cleaning for various volumes of sand'},
      {fieldName:'Power', fieldValue: 'High-performance motor for thorough cleaning'},
      {fieldName:'Controls', fieldValue: 'User-friendly interface for ease of operation'},
      {fieldName:'Construction', fieldValue: 'Robust fabrication for durability'},
      {fieldName:'Applications', fieldValue: 'Suitable for various industries requiring sand cleaning'},
      {fieldName:'Performance ', fieldValue: 'Ensures impeccable cleanliness'},
      {fieldName:'Reliability ', fieldValue: 'Dependable operation for consistent results'}, 	
    ],
    description: 'Our innovative sand washer machine is a compact, powerful tool designed to efficiently clean sand particles. Engineered with precision, it can tackle even the toughest grime and debris. Its user-friendly interface and high-performance capabilities ensure impeccable cleanliness, making it an indispensable tool for various industries. Experience unparalleled reliability and effectiveness with our cutting-edge sand washer machine.', 
    features:['Superior Cleaning Efficiency', 'Enhanced Production Efficiency', 'Durable Construction', 'Reduced Water Consumption', 'Customizable Solutions', 'Easy Maintenance'],
    images: [SW1, SW2, SW3], 
    imgHeight:'230px'
  },
  //5
  { 
    name:'Ceramic Fiber Paper unit (Die,Mold,Vacuum tank)',
    tableContent:[ 
      {fieldName:'Material', fieldValue: 'High-purity ceramic fiber paper'},
      {fieldName:'Temperature Rating', fieldValue: 'Up to 1400°C (2552°F) (Depending on specific material grade)'},
      {fieldName:'Thickness', fieldValue: 'Customizable thicknesses available (e.g., 3mm, 6mm, 10mm)'},
      {fieldName:'Die/Mold Compatibility', fieldValue: 'Adaptable to various die and mold shapes and sizes'},
      {fieldName:'Vacuum Tank Compatibility', fieldValue: 'Suitable for a range of vacuum tank configurations'},
      {fieldName:'Installation', fieldValue: 'Easy installation and removal for maintenance'}, 	
    ],
    description: 'Introducing our Ceramic Board Farming Machine, Ceramic Blanket Chamber Machine, and Ceramic Oven Machine: revolutionizing the ceramics industry with precision, efficiency, and reliability. Crafted with cutting-edge technology and expert craftsmanship, our machines ensure optimal production outcomes, from ceramic board cultivation to precise temperature control in ceramic blanket chambers and ovens. Elevate your ceramics production to new heights with our innovative solutions.', 
    features:['Exceptional Thermal Insulation', 'Energy Efficiency', 'Lightweight and Flexible','High Strength and Durability','Excellent Chemical Resistance','Cost-Effective Solution'],
    images: [CPU1, CPU2], 
    imgHeight:'400px'
  },
  //6
  { 
    name:'Ceramic Board farming Machine (Vacuum tank,mixing tank,Die,Mold, Face & edge Grinding machine)',
    tableContent:[ 
      {fieldName:'Material Compatibility', fieldValue: 'Various ceramic formulations (Specify upon inquiry)'},
      {fieldName:'Production Capacity', fieldValue: 'Customizable based on model and desired output'},
      {fieldName:'Automation Level', fieldValue: 'Manual / Semi-automatic / Fully-automatic (Depending on model)'},
      {fieldName:'Control System', fieldValue: 'User-friendly interface for process monitoring and adjustments'},
      {fieldName:'Safety Features', fieldValue: 'Equipped with safety measures to ensure operator protection'}, 	
    ],
    description: 'Introducing our advanced ceramic board farming machine, designed to streamline the production process with efficiency and precision. Equipped with a vacuum tank for optimal material handling, a mixing tank for consistent composition, and specialized dies and molds for shaping, our machine ensures top-quality ceramic board output. Additionally, the integrated face edge grinding machine guarantees smooth and precise finishing. Experience enhanced productivity and impeccable craftsmanship with our ceramic board farming machine.', 
    features:['Integrated Functionality', 'Versatile Applications', 'Customization Options', 'Enhanced Process Control','Improved Quality','Reduced Labor Costs'],
    images: [], 
    imgHeight:'400px'
  },
  //7
  {
    name:'Ceramic Fiber Blanket line (Collection Chamber,niddler machine,Annealing Oven)',
    tableContent:[
      {fieldName: 'Collection Chamber', fieldValue: 'Captures ceramic fibers after processing, ensuring a clean and controlled environment.'}, 
      {fieldName:'Needler Machine', fieldValue: 'Forms a uniform and consistent ceramic fiber blanket using a needling process.'},
      {fieldName:'Annealing Oven', fieldValue: 'Cures the ceramic fiber blanket at controlled temperatures to enhance its properties.'}, 	
    ],
    description: 'Introducing our Ceramic Fiber Blanket line, engineered to deliver top-quality insulation solutions with unmatched efficiency. The collection chamber ensures seamless material gathering, while the needler machine enhances fiber alignment for superior strength and durability. With our annealing oven, precise temperature control guarantees optimal performance and uniformity in every blanket produced. Experience exceptional insulation properties and reliable performance with our Ceramic Fiber Blanket line.', 
    features:['Superior Thermal Insulation', 'Enhanced Process Efficiency', 'Customizable Blanket Properties','Durable and Long-Lasting','Versatile Applications'],
    images: [], 
    imgHeight:'400px'
  },
  //8
  {
    name:'Screw Conveyor',
    tableContent:[
      {fieldName: 'Material Handling Capacity', fieldValue: 'Up to 1000 tph (tons per hour) (Depending on model and material)'}, 
      {fieldName:'Material Compatibility', fieldValue: 'Powders, granules, flakes, and other dry, bulk materials (Specify upon inquiry)'},
      {fieldName:'Orientation', fieldValue: 'Horizontal, inclined, or vertical (Depending on model)'},
      {fieldName:'Screw Flight Options', fieldValue: 'Various flight configurations to optimize material flow (e.g., solid, ribbon, cut flight)'},
      {fieldName:'Construction Materials', fieldValue: 'Mild steel, stainless steel, or other options for specific applications'},
      {fieldName:'Drive System', fieldValue: 'Electric motor with variable speed control (Optional)'}, 	
    ],
    description: 'A screw conveyor is a precise and robust material handling unit with a helical screw rotating around a central shaft. It is suitable for wet and non-flowing materials and is used in industries like pharmaceuticals, chemicals, food and beverages, and agriculture. The screws design depends on the material and construction material, typically carbon steel.', 
    features:['Robust construction', 'Corrosion resistant', 'Highly efficient','Highly durable'],
    images: [SBC3], 
    imgHeight:'230px'
  },
  //9
  {
    name:'Belt Conveyor',
    tableContent:[
      {fieldName: 'Capacity', fieldValue: 'Up to 5000 tph (tons per hour) (Depending on model and material)'}, 
      {fieldName:'Material', fieldValue: 'Boxes, packages, bulk materials, and irregular shaped objects (Specify upon inquiry)'},
      {fieldName:'Length', fieldValue: 'Customizable lengths to meet your specific needs'},
      {fieldName:'Inclination', fieldValue: 'Flat or inclined conveyors for horizontal or vertical transport'},
      {fieldName:'Belt Options', fieldValue: 'Various belt types available (e.g., PVC, rubber, stainless steel) to suit material and application'},
      {fieldName:'Control System', fieldValue: 'Optional features include variable speed control, emergency stop buttons, and safety sensors.'}, 	
    ],
    description: 'We offer a high-quality conveyor belt that is used for different conveying applications in various industries. It is effectively used for fine and loose material. Our belt conveyors efficiently convey material or products without littering them. It contains a troughed tray to place the material. We offer troughed trays with capacities ranging from 100 TPH to 2000 TPH. Our belt conveyors are available with different elevation angles ranging from 15 degrees to 45 degrees.', 
    features:['Vibration free functioning', 'Least noise', 'Highly durable', 'Minimum maintenance'],
    images: [SBC1,SBC2], 
    imgHeight:'230px'
  },
];

const MSWorks = () => {

  useEffect(()=>{
    document.title='Sai Kripa Engineering MS Works - High-Quality Mild Steel Fabrication';
    document.description='Sai Kripa ENgineering specializes in the design, fabrication, and installation of durable mild steel (MS) machinery for industrial applications. Explore our MS services and contact us for a free quote!';
  },[]);

  // Video Header
  const headerData = {
    vid: videoBg,
    title: "Sai Kripa Engineering's MS Works",
    description: "Because Your Projects Demand Toughness."
  };

  return (
    <Base>
      <VideoHeader videoBg={headerData.vid} title={headerData.title} description={headerData.description} />

      {/** WORK SECTION */}
      <div id='work'>
        <Container className='mt-5'>
          <div data-aos='fade-up'>
            <h2 className='title'> Our Products & Services </h2>
            <p className='subtitle'>We provides various engineering services.</p>
          </div>
          <Container className='mt-5'>
              {works.map((product) => (
                <Container className='mt-5'>
                  <div data-aos='fade-up'>
                    <h3>{product.name}</h3>
                    <p>{product.description}</p>
                  </div>
                  
                  <Row className='ms-2 mb-4'>
                    {product.images.map((item)=>(
                      <Col data-aos='flip-right' md={4} className='mb-4'>
                        <img src={item} height={product.imgHeight}  />
                      </Col>
                    ))}
                  </Row>
                  <div data-aos='fade-up'>
                    <h4>Product Details</h4>
                    <Table className='mt-4 mb-4'>
                      <tbody>
                        {product.tableContent.map((item)=>(
                          <tr>
                          <th scope="row">{item.fieldName}</th>
                          <td>{item.fieldValue}</td>
                        </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  
                  <div data-aos='fade-up'>
                    <h4>Features</h4>
                    <ul>
                      {product.features.map((item)=>(
                        <li><span className='primary'>&#11162;</span> {item}</li>
                      ))}
                    </ul>
                  </div>
                  
                </Container>
              ))}
            </Container>
        </Container>
      </div>
    </Base>
  )
}

export default MSWorks
