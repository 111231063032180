import React from 'react'
import { Button, Card, CardBody, CardHeader, CardImg } from 'reactstrap'
import CustomCrousel from './CustomCrousel'

const ServiceCard = ({data}) => {
  return (
    <div className='service-card'>
      <Card>
        <CustomCrousel images={data.images}/>
        <CardBody>
            <div className='text-start'>
            <h3 data-aos='fade-up' className='primary'>{data.name}</h3>
            <p data-aos='fade-up'>{data.description}</p>
            <a data-aos='fade-up' href={data.href} className="btn">Read More</a>
            </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default ServiceCard
