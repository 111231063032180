import React, { useEffect } from 'react'
import Base from '../Component/Base'
import VideoHeader from '../Component/VideoHeader'
import videoBg from '../assets/videos/About-heading.mp4';
import aboutVid1 from '../assets/videos/About-1.mp4';
import aboutVid2 from '../assets/videos/About-2.mp4';
import aboutVid3 from '../assets/videos/About-3.mp4';
import Team from '../Component/Team';
import TouchLine from '../Component/TouchLine';
import { Col, Container, Row } from 'reactstrap';

const About = () => {

    useEffect(()=>{
        document.title='About Sai Kripa Engineering - Your Trusted Partner in SS & MS Heavy Machinery';
        document.description=' Sai Kripa Engineering designs and manufactures high-quality stainless steel & mild steel heavy machinery. Our team of experts delivers innovative solutions for industrial applications. Contact us today!'
      },[]);

    //header video
    const headerData = {
        vid: videoBg,
        title: "About Us",
        description: "We Don't Just Build Machinery, We Build Success."
    };

  return (
    <Base>
        <VideoHeader videoBg={headerData.vid} title={headerData.title} description={headerData.description} />
        {/* About Section */}
        <div className='about-bg'>
            <Container className="about-main">
            <Row>
                <Col data-aos='fade-up' md={12}>
                <h2>Welcome to <span className="primary">SKE</span></h2>
                <p>A renowned fabrication engineering company dedicated to delivering exceptional solutions tailored to your needs. With over two decades of experience, we've established ourselves as a trusted provider in the engineering works & fabrication industry, specializing in MS and SS Works.</p>
                <p>At <span className='primary'>Sai Kripa Engineering</span>, we cultivate a team of elite engineers and designers. Years of industry experience, coupled with a dedication to continuous learning, ensure our staff remains at the forefront of their respective fields. We prioritize recruiting individuals with a relentless pursuit of excellence, fostering an environment where entrepreneurial spirit and unwavering integrity thrive. This commitment to attracting and developing high-caliber talent fuels our consistent growth and ongoing success, ultimately allowing us to deliver exceptional results for our clients.</p>
                </Col>
            </Row>
            <p>Our unwavering commitment to quality ensures every project we undertake exceeds expectations. We believe that collaboration is essential to success, and we work closely with our clients to understand their unique requirements. This collaborative approach, combined with our skilled workforce and state-of-the-art equipment, allows us to deliver robust, cost-effective, and innovative solutions.</p>
            <p>Let <span className='primary'>Sai Kripa Engineering</span> be your partner in bringing your fabrication vision to life. <span className='primary'>Contact us today to discuss your project!</span></p>
            </Container>
            <Container className="about-main">
            <Row>
                <Col data-aos='fade-up' md={6}>
                <h2>Our Vision</h2>
                <p><span className='primary'>Sai Kripa Engineering</span> envisions itself as the premier fabrication engineering company, recognized for:</p>
                <ul>
                    <li><span>&#11162;</span><span className='primary'>Unmatched quality and innovation:</span> Delivering cutting-edge solutions at the forefront of the industry.</li>
                    <li><span>&#11162;</span><span className='primary'>Unwavering client focus:</span> Partnering with clients to understand their unique needs and exceed expectations.</li>
                    <li><span>&#11162;</span><span className='primary'>Exceptional talent:</span> Attracting and retaining a team of passionate, highly-skilled individuals.</li>                     
                </ul>
                </Col>
                <Col data-aos="fade-right" md={6}>
                    <video src={aboutVid2} className='rounded' autoPlay loop muted />
                </Col>
            </Row>
            <p>By remaining at the forefront of engineering & fabrication technology and fostering a collaborative environment, <span className='primary'>Sai Kripa Engineering</span> strives to contribute to the advancement of the industry, ultimately shaping a future where innovative and sustainable fabrication solutions drive progress and success for our clients and partners.</p>
            </Container>
            <Container className="about-main">
            <Row>
                <Col data-aos='fade-up' md={6}>
                <h2>Our Mission</h2>
                <p><span className='primary'>Sai Kripa Engineering</span>  is dedicated to exceeding client expectations by providing exceptional, tailored fabrication solutions in MS and SS works. We achieve this through a combination of:</p>
                <ul>
                    <li><span>&#11162;</span><span className='primary'>Deep industry expertise:</span> Our team of experienced engineers and designers leverages over two decades of knowledge.</li>
                    <li><span>&#11162;</span><span className='primary'>Relentless pursuit of excellence:</span> We cultivate a culture of continuous learning and innovation.</li>
                    <li><span>&#11162;</span><span className='primary'>Unwavering integrity:</span> We prioritize ethical practices and building trust with our clients.</li>                     
                </ul>
                </Col>
                <Col data-aos='fade-left' md={6}>
                <video src={aboutVid3} className='rounded' autoPlay loop muted />
                </Col>
            </Row>
            <p>Through this dedication to exceptional service, <span className='primary'>Sai Kripa Engineering</span> empowers our clients to achieve their project goals with confidence.  We ensure their vision becomes reality by providing:</p>
            <ul>
                <li><span>&#11162;</span><span className='primary'>Cost-effective solutions:</span> Delivering value alongside exceptional quality.</li>
                <li><span>&#11162;</span><span className='primary'>Streamlined project execution:</span> Ensuring efficiency and minimizing disruption.</li>
                <li><span>&#11162;</span><span className='primary'>Long-lasting, reliable results:</span> Fabrications built to exceed expectations and stand the test of time.</li>
            </ul>
            </Container>
        </div>

        {/* TEAM SECTION */}
        <div id='team'>
          <div className="container">
            <div data-aos='fade-up'>
              <h2 className='title'> Our Expert Team </h2>
              <h5 className='subtitle'>Meet the Minds Behind Our Success</h5>
            </div>
            <Team />
          </div>
        </div>

        <TouchLine />
    </Base>
  )
}

export default About