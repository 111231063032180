import React from 'react'
import './Footer.css'
import { Col, Container, Row } from 'reactstrap'
import { FaEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import { address, contact, email, social } from './AppConstants'
import { FaPhone } from 'react-icons/fa6'
import { MdKeyboardDoubleArrowRight, MdLocationOn } from 'react-icons/md'
import MyLogo from '../assets/Logo.png';

const Footer = () => {
  return (
    <footer id='footer'>
        <Container id='footer-widgets' className='m-0 p-4' >
          <Row>
            <Col md={4} data-aos="flip-left" className='p-4'>
              <div className='widget widget-text'>
                {/*<h2 className='widget-title'><span>ABOUT US</span></h2>*/}
                <div className='textwidget'>
                  <a className='navbar-brand' href='/'> <img src={MyLogo} alt='logo' style={{borderStyle:"solid" , borderRadius:'11px', padding:'2px', borderColor: '#fff'}} /> </a>
                  <p>Welcome to Sai Kripa Engineering, a renowned fabrication engineering company dedicated to delivering exceptional solutions tailored to meet your needs. With a legacy spanning over two decades, we have established ourselves as a trusted provider in the fabrication industry.</p>
                </div>
              </div>
            </Col>
            <Col md={4} data-aos="flip-up" className='p-4'>
              <div className='widget widget_links'>
                <h2 className='widget-title'> <span>QUICK LINKS</span> </h2>
                <ul className="wprt-links clearfix col2">
                  <Row>
                    <Col md={6}>
                      <li><a href="/"> <MdKeyboardDoubleArrowRight /> Home</a></li>
                    </Col>
                    <Col md={6}>
                      <li><a href="/about"><MdKeyboardDoubleArrowRight /> About Us</a></li>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                    <li><a href="/ms-works"><MdKeyboardDoubleArrowRight /> M.S Works</a></li>
                    </Col>
                    <Col md={6}>
                    <li><a href="/ss-works"><MdKeyboardDoubleArrowRight /> S.S Works</a></li>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                    <li><a href="/contact"><MdKeyboardDoubleArrowRight /> Contact Us</a></li>
                    </Col>
                    
                  </Row>
                </ul>
              </div>
            </Col>
            <Col md={4} data-aos="flip-right" className='p-4'>
              <div className='widget widget-information'>
                <h2 className='widget-title'> <span>CONTACT INFO</span> </h2>
                <ul>
                  <li className="address clearfix">
                      <span className='primary'>
                        <MdLocationOn style={{marginBottom:'3px', marginRight:'5px'}}/>
                        <span className="hl"> Address:</span>
                      </span>
                      <span className="text" style={{textAlign:"left"}}>{address}</span>
                  </li>
                  <li className="phone clearfix">

                      <span className='primary'>
                        <FaPhone style={{marginBottom:'3px', marginRight:'5px'}}/>
                        <span className="hl"> Phone:</span>
                      </span> 
                      <span className="text">{contact}</span>
                  </li>
                  <li className="email clearfix">
                      <span className='primary'>
                        <FaEnvelope style={{marginBottom:'3px', marginRight:'5px'}}/>
                        <span className="hl"> E-mail:</span>
                      </span>
                      
                      <span className="text">{email}</span>
                  </li>
                </ul>
              </div>
              <div className='widget widget_socials'>
                <div className='socials'>
                  <a target='_blank' href={social.facebookLink}> <FaFacebookF /> </a>
                  <a target='_blank' href={social.instagramLink}> <FaInstagram /> </a>
                  <a target='_blank' href={social.linkedinLink}> <FaLinkedinIn /> </a>
                  <a target='_blank' href={social.twitterLink}> <FaTwitter /> </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div id='bottom' className='clearfix style-1'>
          <Container>
            <div id='bottom-bar-inner' className='wprt-container'>
              <div className='bottom-bar-inner-wrap'>
                <div className='bottom-bar-content'>
                  <div id='copyright'>All Rights Reserved. <span className='primary'>SKE © 2024</span> </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </footer>
  )
}

export default Footer
