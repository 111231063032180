import React, { useEffect } from 'react'
import Base from '../Component/Base'
import VideoHeader from '../Component/VideoHeader'
import videoBg from '../assets/videos/Home-Page-Header.mp4'
import { Col, Container, Row } from 'reactstrap'
import TouchLine from '../Component/TouchLine'
import ServiceCard from '../Component/ServiceCard'
import MSWork1 from '../assets/MS-works/MS-header1.jpg';
import MSWork2 from '../assets/MS-works/MS-header2.jpg';
import MSWork3 from '../assets/MS-works/MS-header3.jpg';
import MSWork4 from '../assets/MS-works/MS-header4.jpg';
import SSWork1 from '../assets/SS-works/SS-header1.jpg';
import SSWork2 from '../assets/SS-works/SS-header2.jpg';
import SSWork3 from '../assets/SS-works/SS-header3.jpg';
import SSWork4 from '../assets/SS-works/SS-header4.jpg';
import Team from '../Component/Team'

const Home = () => {
  
  useEffect(()=>{
    document.title='Sai Kripa Engineering | Engineering Solutions for SS & MS Heavy Machinery';
    document.description='Sai Kripa Engineering designs and manufactures high-quality SS & MS heavy machinery for industrial applications. Contact us today for a free quote!'
  },[]);

  //header video
  const headerData = {
    vid: videoBg,
    title: "Empowering Industries, Engineering Excellence.",
    description: "Crafting Excellence, Engineering Solutions."
  };

  // Services Array
  const services = [
    {
      id: 1, 
      name: 'M.S Works', 
      description: 'We excel in the design, fabrication, and installation of mild steel structures and equipment. Our expertise in MS works covers a wide range of applications, including Ammonia Receiver, Pressure Vessel & Tank, Sand Washer, Ceramic Fiber, Storage Tank, etc.', 
      images: [MSWork1,MSWork2,MSWork3,MSWork4], 
      fade:false, 
      href:'/ms-works', 
      imgHeight:'350px'
    },
    {
      id: 2, 
      name: 'S.S Works', 
      description: 'We design, fabricate, and install large-scale structures and equipment made from stainless steel. Our capabilities include Ball Mill, Holding Tank, Melting Tank, Choclate Slab Cutter Machine, Gem Cotting Pane, SS Belt & Chain Conveyor, Chimney etc.', 
      images: [SSWork1,SSWork2,SSWork3,SSWork4], 
      fade:false, 
      href:'/ss-works', 
      imgHeight:'350px'
    },
  ]

  return (
    <Base>
      <VideoHeader videoBg={headerData.vid} title={headerData.title} description={headerData.description}/>

      {/* About Section */}
        <Container className="about-main">
          <Row>
            <Col lg={12}>
              <div data-aos="fade-up">
                <h2 >Welcome to <span className="primary">Sai Kripa Engineering</span></h2>
                <p >Welcome to <span className='primary'>Sai Kripa Engineering</span>, a renowned fabrication engineering company dedicated to delivering exceptional solutions tailored to meet your needs. It was established in 2015. Our vision and collaborative spirit laid the foundation for the company's success in the fabrication engineering industry. With a legacy spanning over two decades, we have established ourselves as a trusted provider in the fabrication industry.</p>
              
              {/*<p>At <span className='primary'>Sai Kripa Engineering</span>, we cultivate a team of elite engineers and designers. 20 Years of industry experience, coupled with a dedication to continuous learning, ensure our staff remains at the forefront of their respective fields. We prioritize recruiting individuals with a relentless pursuit of excellence, fostering an environment where entrepreneurial spirit and unwavering integrity thrive. This commitment to attracting and developing high-caliber talent fuels our consistent growth and ongoing success, ultimately allowing us to deliver exceptional results for our clients.</p>*/}
              
                <h5>Who We Are:</h5>
                <p>At <span className='primary'>Sai Kripa Engineering</span>, we take pride in our commitment to excellence and innovation. With team of skilled professionals and state-of-the-art facilities, we specialize in delivering high-quality fabrication solutions that exceed expectations.</p>
              </div>
              <div data-aos="fade-up">
                <h5>Our Expertise:</h5>
                <p className='list-item'>Specializing in fabrication engineering, we offer a wide range of services including but not limited to:</p>
                <ul>
                    <li><span>&#11162;</span><span className='list-item'>Structural Steel Fabrication</span> </li>
                    <li><span>&#11162;</span><span className='list-item'>Custom Metal Fabrication</span> </li>
                    <li><span>&#11162;</span><span className='list-item'>Welding and Assembly</span> </li>
                    <li><span>&#11162;</span><span className='list-item'>Precision Machining</span> </li>
                    <li><span>&#11162;</span><span className='list-item'>Design and Engineering Support</span> </li>
                    <li><span>&#11162;</span><span className='list-item'>Installation and Maintenance Services</span> </li>                     
                </ul>
                <p className='list-item'>No matter the complexity or scale of your project, Sai Kripa Engineering has the expertise and resources to deliver superior results.</p>
              </div>
              
            </Col>
          </Row>
        </Container>

        {/* SERVICE SECTION */}
        <div id='service'>
          <div className="container">
            <h2 data-aos='fade-up' className='title'> Our Products & Services </h2>
            <h5 data-aos='fade-up' className='subtitle'>From MS Works to SS works, we offer comprehensive engineering support.</h5>
            <Container m-5>
              <Row>
                {
                  services.map((service)=>(
                    <Col md={6} className='mt-5'>
                      <ServiceCard data={service}/>
                    </Col>
                  ))
                }
              </Row>
            </Container>
          </div>
        </div>

        {/* Why Us Section */}
        <div className='about-bg'>
          <Container className="about-main">
            <Row>
              <Col lg={12} data-aos='fade-up'>
                <h2>Why Us</h2>
                <p>At <span className='primary'>Sai Kripa Engineering</span>, we are passionate about providing exceptional engineering solutions that empower your projects to thrive. We go beyond simple design and drafting, we become an extension of your team, collaborating closely to bring your vision to reality. Here's what sets Sai Kripa Engineering apart:</p>
                <h5>Our smart approach</h5>
                <ul>
                    <li><span>&#11162;</span><span className='primary'>Expertise:</span> With over two decades of experience in the industry, we possess the knowledge and skills to tackle even the most challenging projects.</li>
                    <li><span>&#11162;</span><span className='primary'>Quality Assurance:</span> We adhere to stringent quality control measures to ensure that every product meets the highest standards of excellence.</li>
                    <li><span>&#11162;</span><span className='primary'>Custom Solutions:</span> We work closely with clients to understand their unique requirements and deliver tailored solutions that precisely meet their needs.</li>  
                    <li><span>&#11162;</span><span className='primary'>Timely Delivery:</span> We understand the importance of deadlines and strive to deliver projects on time and within budget.</li>
                    <li><span>&#11162;</span><span className='primary'>Customer Satisfaction:</span> Your satisfaction is our top priority, and we are dedicated to exceeding your expectations at every step of the process.</li>                   
                </ul>
              </Col>
            </Row>
          </Container>  
        </div>

        {/* TEAM SECTION */}
        <div id='team'>
          <div className="container">
            <div data-aos='fade-up'>
              <h2 className='title'> Our Expert Team </h2>
              <h5 className='subtitle'>Meet the Minds Behind Our Success</h5>
            </div>
            <Team />
          </div>
        </div>

        <TouchLine />
    </Base>
  )
}

export default Home
