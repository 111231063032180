import React from 'react'
import './Team.css'
import { Col, Row } from 'reactstrap';
import founder1 from '../assets/profile-1.jpg'
import founder2 from '../assets/profile-2.jpg'
import member1 from '../assets/profile-3.jpg'
import member2 from '../assets/profile-4.jpg'
import { FaPhone } from 'react-icons/fa6';

const Team = () => {

    const members = [
        {
            id: 1, 
            name: 'Mr. Rahul Kushwah', 
            title: 'Manager', 
            description: 'Master in Business Administration',
            subDesc: 'Human Resource, Bussiness Analytics & Marketing', 
            image: member1, 
            fade:true, href: '/about', 
            imgHeight:'200px',
            
        },
        {
            id: 2, 
            name: 'Mr. Aaditya Kushwah', 
            title: 'Manager', 
            description: 'Bechalor of Technology',
            subDesc: 'Computer Science', 
            image: member2,
            fade:true, href: '/about', 
            imgHeight:'100px',
            
        },
    ];
    
    const founders = [
        {
            id: 1, 
            name: 'Mr. Rameshwar Kushwah', 
            title: '', 
            description: '', 
            image: founder1, 
            fade:true, 
            href: '/about', 
            imgHeight:'350px',
            phone: "+91 8878350068"
        },
        {
            id: 2, 
            name: 'Mr. Mukti Nath Kushwah', 
            title: '', 
            description: '', 
            image: founder2, 
            fade:true, 
            href: '/about', 
            imgHeight:'350px',
            phone: '+91 7509524290'
        }
    ];

  return (
    <div>
        <h4 className='subtitle m-5'>Founders</h4>
      <Row>
        {
            founders.map((founder)=>(
                <Col md={6} className='mt-3 mb-3 details'>
                    <img data-aos='flip-right' src={founder.image}  height={200} className='mb-2'/>
                    <h5 data-aos='fade-up' style={{fontWeight:"600"}}>{founder.name}</h5>
                    <p data-aos='fade-up' style={{marginTop:'-1rem'}}> <FaPhone /> {founder.phone}</p>
                </Col>
            ))
        }
      </Row>
      <h4 className='subtitle m-5'>Management</h4>
      <Row>
        {
            members.map((member)=>(
                <Col md={6} className='mt-3 mb-3 details'>
                    <img data-aos='flip-right' src={member.image} height={200} className='mb-2'/>
                     <h5 data-aos='fade-up' style={{fontWeight:"600"}}>{member.name}</h5>
                     <p data-aos='fade-up' style={{marginTop:'-1rem'}}>{member.description}</p>
                     <p data-aos='fade-up' style={{fontSize:'12px',marginTop:'-2rem'}}>{member.subDesc}</p>
                </Col>  
            ))
        }
      </Row>
    </div>
  )
}

export default Team
