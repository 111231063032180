import React from 'react'
import './TouchLine.css'
import { Col, Container, Row } from 'reactstrap'

const TouchLine = () => {
  return (
    <div className="touch-line">
        <Container>
          <Row>
            <Col data-aos="zoom-in" md={8}>
              <p>Contact us now to discuss your project requirements, get expert advice, and explore innovative solutions tailored to your needs. Don't wait, take the first step towards success with us!</p>
            </Col>
            <Col data-aos="zoom-out" md={4}>
              <a className="btn btn-lg btn-secondary btn-block contactus-btn" href="/contact"> Contact Us </a>
            </Col>
          </Row>
        </Container>
      </div>
  )
}

export default TouchLine