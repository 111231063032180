import React, { useEffect, useState } from 'react'
import './Header.css'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FaBars, FaTimes } from 'react-icons/fa';
import MyLogo from '../assets/Logo.png';

const Header = () => {
    // Setting mobile nav
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
  
    // Highlighting active link based on pathname
    const [activeLink, setActiveLink] = useState('');
  
    useEffect(() => {
      const currentPath = window.location.pathname;
      setActiveLink(currentPath.slice(1)); // Remove leading slash from pathname
    }, []); // Empty dependency array to run only on initial render
  
    // Close menu on click
    const closeMenu = () => setClick(false);
  
     // Change nav color when scrolling
     const [color, setColor] = useState(false);
     const changeColor = () => {
       if (window.scrollY > 90) {
         setColor(true);
       } else {
         setColor(false);
       }
     };
  
     window.addEventListener('scroll', changeColor);
  
    return (
      <div className={color ? 'header header-bg' : 'header'}>
        <nav className='navbar'>
          <a href='/' className='logo'>
            <img style={{height:'5rem',width:'100%'}}  src={MyLogo} alt='logo' />
          </a>
          <div className='hamburger' onClick={handleClick}>
            {click ? <FaTimes size={30} style={{ color: '#ffffff' }} /> : <FaBars size={30} style={{ color: '#ffffff' }} />}
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className={`nav-item ${activeLink === '' || activeLink === '' ? 'active' : ''}`}>
              <a className='nav-text' href='/' onClick={closeMenu}>Home</a>
            </li>
            
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Products & Services
              </DropdownToggle>
              <DropdownMenu >
                <DropdownItem href='/ms-works'>MS Works</DropdownItem>
                <DropdownItem href='/ss-works'>SS Works</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
  
            <li className={`nav-item ${activeLink === 'about' ? 'active' : ''}`}>
              <a className='nav-text' href='/about' onClick={closeMenu}>About</a>
            </li>
  
            <li className={`nav-item ${activeLink === 'contact' ? 'active' : ''}`}>
              <a className='nav-text' href='/contact' onClick={closeMenu}>Contact Us</a>
            </li>
          </ul>
        </nav>
      </div>
    );
  };
  

export default Header