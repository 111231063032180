import React, { useEffect } from 'react'
import VideoHeader from '../Component/VideoHeader'
import Base from '../Component/Base'
import { Col, Container, Row, Table } from 'reactstrap';
import videoBg from '../assets/videos/ss.mp4';

import STORE1 from '../assets/SS-works/SS-header3.jpg'
import STORE2 from '../assets/SS-works/SS-header2.jpg'
import STORE3 from '../assets/SS-works/storage-tank3.jpg'
import HOLD1 from '../assets/SS-works/holding-tank1.jpg';
import HOLD2 from '../assets/SS-works/SS-header1.jpg';
import HOLD3 from '../assets/SS-works/holding-tank3.jpg';


const works = [
  //1
  { 
    name: 'Chocolate Stainless Steel Ball Mills', 
    tableContent:[
      {fieldName: 'Model ', fieldValue: 'Chocolate SS Ball Mills '}, 
      {fieldName:'Material ', fieldValue: 'Stainless steel construction'},
      {fieldName:'Capacity', fieldValue: 'Variable capacity depending on model'},
      {fieldName:'Grinding mechanism', fieldValue: 'Ball milling process for chocolate refining'},
      {fieldName:'Control system', fieldValue: 'Programmable control for precise operation'},
      {fieldName:'Speed range', fieldValue: 'Adjustable speed for different processing needs'},
      {fieldName:'Safety features', fieldValue: 'Emergency stop, overload protection'},
      {fieldName:'Cleaning', fieldValue: 'Easy-to-clean design for hygiene maintenance'},
      {fieldName:'Power requirements', fieldValue: 'Voltage and power specifications'},
      {fieldName:'Dimensions', fieldValue: 'Dimensions and weight of the machine'},
      {fieldName:'Optional accessories', fieldValue: 'Additional attachments or features available'}, 	
    ],
    description: 'The Chocolate Stainless Steel Ball Mills machine is a state-of-the-art tool designed to enhance the chocolate production process. Made from premium stainless steel, it ensures precise grinding and refining of ingredients, resulting in smooth, velvety textures. The stainless steel balls ensure uniform mixing, resulting in exquisite chocolate creations.', 
    features:['Superior Refinement', 'Preserves Chocolate Quality', 'Efficient Grinding', 'Temperature Control', 'Easy Cleaning and Maintenance', 'Durable Design'],
    images: [], 
    imgHeight:'400px'
  },
  //2
  { 
    name: 'Chocolate Melting Machine cum Holding Tank', 
    tableContent:[
      {fieldName: 'Model ', fieldValue: 'Chocolate Stainless Steel Holding Tanks'}, 
      {fieldName:'Material ', fieldValue: 'Stainless steel (SS304/SS316) construction'},
      {fieldName:'Capacity', fieldValue: 'Various capacities available (e.g., 100L, 200L, 500L)'},
      {fieldName:'Temperature Control ', fieldValue: 'Precise temperature control system '},
      {fieldName:'Insulation ', fieldValue: 'Insulated walls for temperature retention'},
      {fieldName:'Heating Method', fieldValue: 'Electric heating or water jacket heating'},
      {fieldName:'Mixing System', fieldValue: 'Optional agitation system for uniform mixing'},
      {fieldName:'Cleaning', fieldValue: 'Easy-to-clean design with sanitary fittings'},
      {fieldName:'Control Panel', fieldValue: 'User-friendly control panel for operation'},
      {fieldName:'Safety Features', fieldValue: 'Overheat protection, emergency stop button'},
      {fieldName:'Applications', fieldValue: 'Ideal for chocolate melting and storage'}, 	
    ],
    description: 'The ChocoMan Melt 210 Chocolate Melting Machine cum Holding Tank is used for melting and storing chocolate in liquid condition. This is done by maintaining steady heat as well as agitating the chocolate in the tank. The  ChocoMan Melt 210 Chocolate Melting Machine cum Holding Tank is crafted out of thick guage stainless steel (304 grade) and all the crucial components - motors, gear-box, etc. are sourced from international manufacturers viz. Siemens, ABB, Bonfiglioli, etc. ensuring smooth production and years of good service.', 
    features:['Made from thick-guage 304 grade Stainless Steel both Holding Tank as well as outer body','Agitator arms fitted with heavy-duty Motor and Gear Box','Water-jacketed to ensure even and proper heat','Heaters controlled by electronic Temperature Controller','Also available in 100 litre capacity'],
    images: [HOLD1, HOLD2, HOLD3], 
    imgHeight:'300px'
  },
  //3
  { 
    name: 'Chocolate Moulds machine', 
    tableContent:[
      {fieldName: 'Type', fieldValue: 'Depositing / Enrobing (Specify upon inquiry)'}, 
      {fieldName: 'Capacity', fieldValue: 'Up to 500 molds per hour (Depending on model)'},
      {fieldName: 'Chocolate Loading Capacity', fieldValue: 'Up to 200 L (Depending on model)'},
      {fieldName: 'Mould Compatibility', fieldValue: 'Various mould sizes and designs (Customizable options available)'},
      {fieldName: 'Mould Feeding System', fieldValue: 'Automatic or Manual (Depending on model)'},
      {fieldName: 'Cooling System', fieldValue: 'Integrated cooling tunnel for efficient chocolate solidification (Optional)'},
      {fieldName: 'Cleaning System', fieldValue: 'Easy-to-clean design for optimal hygiene (Optional)'},	
    ],
    description: 'The Chocolate Moulds machine is a precision chocolate molding tool, designed with stainless steel precision for flawless detail and consistency. It offers a variety of molds for easy production of chocolates in various shapes and sizes, enhancing confectionery production and unlocking endless possibilities in chocolate craftsmanship.', 
    features:['Automated Efficiency','High Production Capacity','Versatility','Consistent Quality','Improved Workflow','Scalability'],
    images: [], 
    imgHeight:'400px'
  },
  //4
  {
    name: 'Chocolate Slab Cutter machine', 
    tableContent:[
      {fieldName: 'Cutting Type', fieldValue: 'Guillotine / Wire Cutting (Specify upon inquiry)'}, 
      {fieldName: 'Capacity', fieldValue: 'Up to 1000 slabs per hour (Depending on model)'},
      {fieldName: 'Slab Size Compatibility', fieldValue: 'Customizable for various slab dimensions'},
      {fieldName: 'Cutting Options', fieldValue: '	Adjustable cutting width and depth for precise portioning'},
      {fieldName: 'Feeding System', fieldValue: 'Automatic or Manual (Depending on model)'},
      {fieldName: 'Cooling System', fieldValue: 'Optional integrated cooling system to maintain optimal chocolate consistency for cutting (Depending on model)'},
      {fieldName: 'Cleaning System', fieldValue: 'Easy-to-clean design for efficient sanitation'},
      ],
    description: 'Introducing our Chocolate Slab Cutter machine – the perfect solution for precision cutting of chocolate slabs. Crafted with stainless steel precision, this machine ensures uniform and clean cuts every time, enhancing the efficiency of your chocolate production process. With adjustable settings, you can customize the thickness of each slab to meet your specific requirements. Streamline your chocolate manufacturing with our Chocolate Slab Cutter machine and elevate the quality of your confections with ease.', 
    features:['Precise Cutting','Versatility','High Production Efficiency','Improved Presentation','Reduced Labor Costs','Easy Integration'],
    images: [], 
    imgHeight:'400px'
  },
  //5
  {
    name: 'Stainless Steel Belt & Chain Conveyor', 
    tableContent:[
      {fieldName: 'Type', fieldValue: 'Belt Conveyor / Chain Conveyor (Specify upon inquiry)'}, 
      {fieldName: 'Material Handling Capacity', fieldValue: 'Up to 1000 tph (tons per hour) (Depending on model and material)'},
      {fieldName: 'Material Compatibility', fieldValue: 'Ideal for food, pharmaceuticals, chemicals, and other applications requiring sanitation'},
      {fieldName: 'Belt/Chain Material', fieldValue: 'Food-grade stainless steel'},
      {fieldName: 'Frame Material', fieldValue: 'Stainless steel or mild steel with painted finish (Depending on model)'},
      {fieldName: 'Length', fieldValue: 'Customizable lengths to meet your specific needs'},
      {fieldName: 'Inclination', fieldValue: 'Flat or inclined conveyors for horizontal or vertical transport'},
      {fieldName: 'Cleaning System', fieldValue: 'Optional features include washdown capabilities and integrated sanitation sprays.'},
      ],
    description: 'Our Stainless Steel Belt & Chain Conveyor is a durable and hygienic solution for efficient material handling in various industries. With customizable belt and chain options, it ensures smooth and reliable transportation, streamlining production processes and ensuring high-performance material handling.', 
    features:['Superior Sanitation','Corrosion Resistance','Durable Design','Versatility','Scalable Solutions','Easy Maintenance'],
    images: [], 
    imgHeight:'400px'
  },
  //6
  {
    name: 'Stainless Steel Tanks and Vessels', 
    tableContent:[
      {fieldName: 'Material', fieldValue: 'High-grade Stainless Steel (AISI 304, 316L, etc.)'}, 
      {fieldName: 'Capacity', fieldValue: 'From 50 liters to over 150,000 liters (Depending on model and design)'},
      {fieldName: 'Pressure Rating', fieldValue: 'Vacuum to High Pressure (Depending on design)'},
      {fieldName: 'Jacketing Options', fieldValue: 'Jackets for heating, cooling, or maintaining temperature'},
      {fieldName: 'Agitation Systems', fieldValue: 'Mixers, impellers, or other agitation mechanisms (Optional)'},
      {fieldName: 'Cleaning Systems', fieldValue: 'CIP (Cleaning In Place) systems or manual cleaning options'},
      {fieldName: 'Customization', fieldValue: 'Available in various shapes, sizes, and configurations to meet specific needs'},
      ],
    description: 'Our Stainless Steel Tanks and Vessels are high-quality, durable storage solutions made from premium stainless steel. They offer superior resistance to corrosion and contamination, ensuring the integrity of stored materials. Available in various sizes and configurations, they cater to various industrial applications, including food, beverage, pharmaceuticals, and chemicals. Trust in our reliable, long-lasting storage solutions for uncompromising quality.', 
    features:['Superior Durability','Versatility','Sanitary Design','Temperature Control','Scalability','Cost-Effective'],
    images: [STORE1, STORE2, STORE3], 
    imgHeight:'250px'
  },
  //7
  {
    name: 'Stainless Steel Tables, Stools, Tray Racks', 
    tableContent:[
      {fieldName: 'Stainless Steel Tables', fieldValue: '*Easy to clean and sanitize, * Corrosion-resistant, * Durable for heavy use, * Available in various sizes and styles (e.g., prep tables, workbench tables) * Optional features: adjustable legs, undershelves, casters'}, 
      {fieldName: 'Stainless Steel Stools', fieldValue: '* Smooth, non-porous surface minimizes contamination, * Withstands frequent cleaning and disinfecting, * Durable for various environments, * Available in different heights and with or without backs'},
      {fieldName: 'Stainless Steel Tray Racks', fieldValue: '* Organizes and maximizes storage space, * Easy to clean and maintain, * Corrosion-resistant, * Available in various sizes and configurations, * Optional features: mobile racks, shelves, sloping tiers'},
      ],
    description: 'Introducing our Stainless Steel Tables, Stools, Tray Racks, and more – the perfect companions for confectionery and chocolate companies. Crafted with precision and durability in mind, our stainless steel furniture and equipment provide the ideal workspace and storage solutions for your production needs. From sturdy tables for crafting delectable treats to ergonomic stools for comfortable work environments, our products enhance efficiency and cleanliness in your facility. With versatile tray racks ensuring organized storage, streamline your operations with our premium stainless steel solutions tailored for confectionery and chocolate companies', 
    features:[''],
    images: [], 
    imgHeight:'400px'
  },
];

function SSWorks() {

  useEffect(()=>{
    document.title='Sai Kripa Engineering SS Works - Expert Stainless Steel Fabrication';
    document.description='Sai Kripa Engineering is a leading provider of custom stainless steel (SS) machinery solutions. Our SS fabrication expertise ensures superior durability for your industrial projects. Contact us today!';
  },[]);

  // Header Video
  const headerData = {
    vid: videoBg,
    title: "Sai Kripa Engineering's SS Works",
    description: "Where Performance Meets Perfection. "
  };

  return (
    <Base>
      <VideoHeader videoBg={headerData.vid} title={headerData.title} description={headerData.description} />

      {/** WORK SECTION */}
      <div id='work'>
        <Container className='mt-5'>
          <div data-aos='fade-up'>
            <h2 className='title'> Our Products & Services </h2>
            <p className='subtitle'>We provides various engineering services.</p>
          </div>
          <Container className='mt-5'>
              {works.map((product) => (
                <Container className='mt-5'>
                  <div data-aos='fade-up'>
                    <h3>{product.name}</h3>
                    <p>{product.description}</p>
                  </div>
                  
                  <Row className='ms-2 mb-4'>
                    {product.images.map((item)=>(
                      <Col data-aos='flip-right' md={4} className='mb-4'>
                        <img src={item} height={product.imgHeight}  />
                      </Col>
                    ))}
                  </Row>
                  <div data-aos='fade-up'>
                    <h4>Product Details</h4>
                    <Table className='mt-4 mb-4'>
                      <tbody>
                        {product.tableContent.map((item)=>(
                          <tr>
                          <th scope="row">{item.fieldName}</th>
                          <td>{item.fieldValue}</td>
                        </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  
                  <div data-aos='fade-up'>
                    <h4>Features</h4>
                    <ul>
                      {product.features.map((item)=>(
                        <li><span className='primary'>&#11162;</span> {item}</li>
                      ))}
                    </ul>
                  </div>
                  
                </Container>
              ))}
            </Container>
        </Container>
      </div>
    </Base>
  )
}

export default SSWorks
